import axios from "axios/index";
import UrlUtils from "./url";
// eslint-disable-next-line import/no-mutable-exports
let currencies = [];

const initializeCurrencyMaps = (currencyList) => {
  const unfrozenCurrencyByIso = {};
  const unfrozenCurrencyBySymbol = {};
  const unfrozenCurrencyById = {};

  currencyList.forEach((country) => {
    const currencyObj = {
      symbol: country.currency,
      iso: country.isoCode,
      id: country.id,
      name: country.name, // Adding country name if needed
    };

    unfrozenCurrencyByIso[country.isoCode] = currencyObj;
    unfrozenCurrencyBySymbol[country.currency] = currencyObj;
    unfrozenCurrencyById[country.id] = currencyObj;
  });

  return {
    currencyByIso: Object.freeze(unfrozenCurrencyByIso),
    currencyBySymbol: Object.freeze(unfrozenCurrencyBySymbol),
    currencyById: Object.freeze(unfrozenCurrencyById),
    currencies: Object.freeze(
      currencyList.map((country) => ({
        symbol: country.currency,
        iso: country.isoCode,
        id: country.id,
      }))
    ),
  };
};

// Initialize with empty maps
// eslint-disable-next-line import/no-mutable-exports
let currencyByIso = {};
// eslint-disable-next-line import/no-mutable-exports
let currencyBySymbol = {};
// eslint-disable-next-line import/no-mutable-exports
let currencyById = {};

const fetchCountries = async () => {
  try {
    const response = await axios.get(
      UrlUtils.getProperApiUrl("/yeshtery/currencies")
    );
    const { data } = response; // Remove .json() as axios already parses JSON

    // Update all currency mappings
    const mappings = initializeCurrencyMaps(data);
    currencies = mappings.currencies;
    currencyByIso = mappings.currencyByIso;
    currencyBySymbol = mappings.currencyBySymbol;
    currencyById = mappings.currencyById;

    return data;
  } catch (error) {
    console.error("Error fetching countries:", error);
    return [];
  }
};

const priceFormatting = (currency) =>
  new Intl.NumberFormat("en-US", {
    ...(currency
      ? {
        style: "currency",
        currency,
      }
      : {}),
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const getCurrencyFormatter = (currency) => (value) => {
  if (!Number.isFinite(Number(value))) {
    return "";
  }
  return priceFormatting(currency)
    .format(Math.abs(value))
    .replace("\xa0", "\xa0" + (value < 0 ? "-" : ""));
};

// Immediately fetch and initialize currencies
(async () => {
  await fetchCountries();
})();

export {
  currencies,
  currencyByIso,
  currencyBySymbol,
  currencyById,
  getCurrencyFormatter,
  fetchCountries,
};
